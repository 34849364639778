<template>
  <div class="xtable" >
    <b-card :class="card ? '' : 'xtable__nocard p-0 m-0'" v-if="content && content.length > 0" >
      <x-table-guide :content="content" :title="title" >
        <slot slot="guide-title" slot-scope="{data}">
          <slot name="guide-title" :data="data"></slot>
        </slot>
        <slot slot="guide-content" slot-scope="{data}">
          <slot name="guide-content" :data="data"></slot>
        </slot>
      </x-table-guide>
    </b-card>
    <component :is="isListTable ? 'b-card' : 'div'" :class="card ? '' : 'xtable__nocard p-0 m-0'"  >
      <component v-if="showActions" :is="isListTable ? 'div' : 'b-card'" :class="card ? '' : 'xtable__nocard'">
        <x-table-actions ref="tableAction" :tableType="tableType" :hideStyleSwitch="hideStyleSwitch"
                         :options="actionOptions" :permissions="permissions || {}"
                         :hideSearchBar="hideSearchBar" @switchTableType="switchTableType"
                         class="mb-2"
                         @search="searchHandle" @reset="resetHandle" @add="showAddForm" @print="printHandle" @import="importHandle" @export="exportHandle">
          <template slot="actions">
            <slot name="actions"></slot>
          </template>
          <template v-for="action in actionOptions.columns" :slot="`search-${action.prop}`" slot-scope="scope">
            <slot :name="`search-${action.prop}`" :data="scope.data"></slot>
          </template>
        </x-table-actions>
      </component>
      <b-overlay
        :show="showLoading && loading"
        rounded="sm"
        variant="light"
        opacity="0.85"
        blur="2px"
        spinnerVariant="primary">
        <!--:selectable="rowSelectable"-->
        <!--select-mode="multi"-->
        <template #overlay>
          <div class="card p-1 cx-shadow">
            <div class="text-center d-flex align-items-center">
              <b-spinner variant="primary"></b-spinner>
              <span class="x-fs-5 x-ml-10px x-text-bold">加载中...</span>
            </div>
          </div>
        </template>
        <div v-if="options.rowSelectable && selectedRows && selectedRows.length > 0" class="d-flex justify-content-between align-items-center x-anim-fade-in">
          <div class="btn btn-outline-primary bg-light-primary flex-grow-1">
            <span class="el-icon-info x-mr-5px text-muted"></span><span class="text-muted">已选择</span>
            <span class="x-text-bold text-primary x-ml-5px x-mr-5px">{{selectedRows ? selectedRows.length : 0}}</span><span class="text-muted">项</span>
          </div>
          <slot name="select-actions">
            <div v-if="options.rowSelectedOkBtnShow" class="btn btn-primary ml-1" @click="rowSelectedOk">确定</div>
          </slot>
        </div>
        <b-table
          v-if="isListTable"
          ref="refTable"
          class="x-scrollbar"
          :class="borderless ? '' : 'table_dashed'"
          style="min-height: 20vh"
          :items="pageDatas"
          responsive
          :fixed="fixed"
          :small="small"
          :sticky-header="stickyHeader"
          :striped="striped"
          :borderless="true"
          :hover="hover"
          :fields="tableColumns"
          :primary-key="primaryKey"
          :sort-by.sync="sortBy"
          show-empty
          @row-selected="onRowSelected"
        >
          <template #empty>
            <b-img center class="mb-3 mt-3" height="80" :src="require('@/@core/assets/images/empty.svg')"/>
          </template>
          <template #emptyfiltered>
            <b-img center class="mb-3 mt-3" height="80" :src="require('@/@core/assets/images/empty.svg')"/>
          </template>
          <template v-if="rowSelectable" #head(____check)>
            <b-form-checkbox
              v-model="rowSelectAll"
              :disabled="rowSingleSelectable"
              :indeterminate="!rowSingleSelectable && rowSelected.size > 0 && rowSelected.size < pageDatas.length"
              @change="onAllSelected">
            </b-form-checkbox>
          </template>
          <template :slot="getHead(head)" v-for="(head,index) in tableColumns">
            <div :key="index" class="font-medium-1 x-mt-10px x-mb-10px" :class="head.rowCls" >{{head.label}}</div>
          </template>
          <template #head(____actions)>
            <div class="font-medium-1 x-mt-10px x-mb-10px">操作</div>
          </template>
          <template v-if="rowSelectable" #cell(____check)="row">
            <b-form-checkbox
              v-model="rowModels[row.item[primaryKey]]"
              @change="onSingleRowSelected(row)">
            </b-form-checkbox>
          </template>
          <template v-for="(item,index) in tableColumns" :slot="getCell(item)" slot-scope="scope">
            <slot :name="item.prop" :data="scope.item[item.prop]" :row="scope.item">
              <div v-if="item.dictData && item.dictData.length > 0 "
                   v-tooltip.hover="item.getDictLabel ? item.getDictLabel(getRowDictLabel(item, scope.item)) : scope.item[item.prop]"
                   :key="index">
                <b-badge
                  v-if="item.rowSelect"
                  class="x-text-cut"
                  :class="item.rowCls"
                  :variant="item | getRowSelectVariant(scope.item[item.prop])">
                  {{item.getDictLabel ? item.getDictLabel(getRowDictLabel(item, scope.item)) : scope.item[item.prop]}}
                </b-badge>
                <div  v-else-if="item.rowSelectDot" class="d-flex flex-row align-items-center x-text-cut" :class="item.rowCls">
                  <!--                  <div class="x-dot" :class="item | getRowSelectVariant(scope.item[item.prop])"></div>-->
                  <span class="font-medium-3 mr-50" :class="item | getRowSelectVariant(scope.item[item.prop])">●</span>
                  {{item.getDictLabel ? item.getDictLabel(getRowDictLabel(item, scope.item)) : scope.item[item.prop]}}
                </div>
                <div v-else-if="item.linkUrl" class="text-primary x-text-bold x-link x-text-cut" :class="item.rowCls" @click="linkTo(item.linkUrl,scope.item)">
                  {{item.getDictLabel ? item.getDictLabel(getRowDictLabel(item, scope.item)) : scope.item[item.prop]}}
                </div>
                <div v-else :class="item.rowCls" class="x-text-cut">
                  {{item.getDictLabel ? item.getDictLabel(getRowDictLabel(item, scope.item)) : scope.item[item.prop]}}
                </div>
              </div>
              <div v-else-if="item.type === 'icons'" :key="index" class="x-text-cut" :class="item.rowCls">
                <x-icons :icon="scope.item[item.prop]" cls="btn btn-icon btn-light-primary rounded-circle"></x-icons>
              </div>
              <div v-else-if="item.format && item.type === 'time' || item.type === 'date' || item.type === 'datetime' || item.type === 'datetimerange'"
                   v-tooltip.hover="scope.item[item.prop] ? new Date(scope.item[item.prop]).cxFormat(item.format) : '/'" :key="index" class="x-text-cut" :class="item.rowCls">
                {{scope.item[item.prop] ? new Date(scope.item[item.prop]).cxFormat(item.format) : '/'}}
              </div>
              <div v-else-if="item.type === 'image'" :key="index" class="x-text-cut" :class="item.rowCls">
                <b-avatar v-viewer :src="scope.item[item.prop] ? scope.item[item.prop] : (options.defaultIconUrl || '')"
                          :text="options.defaultIconText || 'CX'" :variant="options.defaultIconVariant || 'light-primary'">
                  <feather-icon v-if="!!options.defaultIcon" :icon="options.defaultIcon" />
                </b-avatar>
              </div>
              <div v-else-if="item.linkUrl" v-tooltip.hover="scope.item[item.prop]" :key="index" class="text-primary x-link x-text-cut" @click="linkTo(item.linkUrl,scope.item)" :class="item.rowCls">
                {{scope.item[item.prop]}}
              </div>
              <div v-else :key="index" v-tooltip.hover="scope.item[item.prop]" class="x-text-cut" :class="item.rowCls">{{scope.item[item.prop]}}</div>
            </slot>
          </template>
          <template #cell(____actions)="data">
            <div class="d-flex align-items-center">
              <x-popconfirm
                ref="deletePopconfirm"
                :target="`${data.item.id}-delete`"
                content="确定要执行删除操作吗？"
                :triggers="dropActions && dropActions.length > 0 ? 'hover' : 'click'"
                @hidden="hideClick"
                @enter="deleteClick(data.item)">
              </x-popconfirm>
              <slot v-for="(item,index) in actions" :name="`row-${item.action}`" :row="data.item">
                <b-button
                  v-permission="item.permission"
                  v-b-tooltip.hover="item.tip"
                  v-toggle="`${item.target ? item.target : ''}`"
                  :disabled="!actionFilter(item.action,data.item)"
                  :key="index"
                  :id="`${data.item.id}-${item.action}`"
                  v-ripple.400="$x.ripple.primary"
                  class="btn-icon rounded-circle x-link"
                  variant="flat-primary"
                  @click="actionClick(item,data)">
                  <span v-if="item.svg" class="svg-icon svg-icon-1x svg-icon-primary">
                    <inline-svg :src="item.svg" />
                  </span>
                  <feather-icon v-else :icon="item.icon" />
                </b-button>
              </slot>
              <el-dropdown v-if="dropActions && dropActions.length > 0" trigger="click" @command="command => actionFilter(command.action,data.item) && actionClick(command,data)">
                <b-button
                  v-ripple.400="$x.ripple.primary"
                  class="btn-icon rounded-circle x-link"
                  variant="flat-primary">
                  <feather-icon icon="MoreVerticalIcon" />
                </b-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="(item,index) in dropActions"
                                    v-permission="item.permission"
                                    :id="`${data.item.id}-${item.action}`"
                                    v-b-tooltip.hover.left="item.tip"
                                    v-toggle="`${item.target ? item.target : ''}`"
                                    :command="item"
                                    :disabled="!actionFilter(item.action,data.item)"
                                    :key="index">
                    <!--@mouseenter="dropActionsHover(item,scope)" @mouseleave="hideClick()"-->
                    <div class="pl-50 pr-1" >
                      <slot :name="`row-${item.action}`" :row="data">
                        <div :class="actionFilter(item.action,data.item) ? '' : 'text-secondary'" class="d-flex align-items-center">
                            <span v-if="item.svg" class="svg-icon svg-icon-sm mr-50" :class="isDark.value ? 'svg-icon-white' : 'svg-icon-dark'">
                              <inline-svg :src="item.svg" />
                            </span>
                          <feather-icon v-else :icon="item.icon" class="mr-50"/>
                          {{item.name}}
                        </div>
                      </slot>
                    </div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <b-dropdown v-if="false && dropActions && dropActions.length > 0" size="sm"  variant="link"  no-caret class="xtable__drop-action"
                          @hide="rowDropActionClick">
                <template #button-content>
                  <b-button
                    v-ripple.400="$x.ripple.primary"
                    class="btn-icon rounded-circle" style="margin-left: -1rem"
                    variant="flat-primary">
                    <feather-icon icon="MoreVerticalIcon" />
                  </b-button>
                </template>
                <!--v-if="actionFilter(item.action,data.item)"-->
                <b-dropdown-item v-for="(item,index) in dropActions"
                                 :id="`${data.item.id}-${item.action}`"
                                 v-b-tooltip.hover="item.tip"
                                 :class="actionFilter(item.action,data.item) ? '' : 'x-only-read'"
                                 @click="actionFilter(item.action,data.item) && actionClick(item,data)" :key="index">
                  <slot :name="`row-${item.action}`" :row="data">
                    <div :class="actionFilter(item.action,data.item) ? '' : 'text-secondary'">
                      <feather-icon :icon="item.icon"  ></feather-icon>
                      <span class="align-middle ml-50">{{ item.name }}</span>
                    </div>
                  </slot>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <div v-else>
          <b-row class="mt-2 ecommerce-application">
            <b-col v-if="pageDatas.length === 0" cols="12">
              <b-img center class="mb-3 mt-3" height="80" :src="require('@/@core/assets/images/empty.svg')"/>
            </b-col>
            <b-col class="xtable__card" :md="cardCols" v-for="(item,index) in pageDatas" :key="index">
              <x-table-card :data.sync="item" :key="index" :columns="tableColumns" :showActions="options.showActions" :defaultIcon="options.defaultIcon"
                            :defaultIconUrl="options.defaultIconUrl"   :defaultIconText="options.defaultIconText" :defaultIconVariant="options.defaultIconVariant"
                            :columnCols="cardColumnCols" :actions="actions" :dropActions="dropActions" :cardProps="cardProps" :index="index"
                            :actionFilter="actionFilter" @actionClick="actionClick" @rowDelete="deleteClick" @beforeCardCreate="beforeCardCreate">
                <slot slot="xt-card-item" slot-scope="scope">
                  <slot name="xt-card-item" :row="scope.row">
                  </slot>
                </slot>
                <slot slot="xt-card-item-header" slot-scope="scope">
                  <slot name="xt-card-item-header" :row="scope.row">
                  </slot>
                </slot>
                <slot v-for="(col) in tableColumns" :slot="col.prop" slot-scope="{data,row}">
                  <slot :name="col.prop" :data="data" :row="row"></slot>
                </slot>
                <slot v-for="(action) in actions" :slot="`row-${action.action}`" slot-scope="{row}">
                  <slot :name="`row-${action.action}`" :row="row"></slot>
                </slot>
                <slot v-for="(drop) in dropActions" :slot="`row-${drop.action}`" slot-scope="{row}">
                  <slot :name="`row-${drop.action}`" :row="row"></slot>
                </slot>
              </x-table-card>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
      <div class="ml-1 mr-1" v-if="!noPage">
        <b-row>
          <b-col  md="4" sm="6"  class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <div class="text-muted">{{'共 ' + total + ' 条记录'}}</div>
            <div class="text-muted ml-1">{{'第 ' + (total === 0 ? 0 : currentPage) + '/' + getTotalPage() + ' 页' }}</div>
          </b-col>
          <b-col
            md="8" sm="12" class="d-flex align-items-center justify-content-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="perPageSize" first-number last-number class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
            <!--            <v-select class="ml-1 text-muted " style="width: 8.8rem" v-model="perPageSize"-->
            <!--                      :options="pages" label="label" :clearable="false" :searchable="false" :reduce="option => option.value">-->
            <!--              <template slot="no-options">{{$t('noData')}}</template>-->
            <!--            </v-select>-->
            <b-dropdown
              v-if="!simplePagination"
              class="ml-1 bg-light-primary" v-ripple.400="'rgba(113, 102, 240, 0.15)'" :text="perPageSize + ' 条/页'" variant="flat-primary">
              <b-dropdown-item :class="item.value === perPageSize ? 'xtable__page-focus' : ''" v-for="(item,index) of pages" @click="getPage(item)" :key="index">
                {{item.label}}
              </b-dropdown-item>
            </b-dropdown>
            <div v-if="!simplePagination" class="d-flex flex-row ml-1 align-items-center">
              <div>跳至</div>
              <b-form-input v-model="jumpPage" class="xtable__page-to ml-1 mr-1" @keyup.enter="jumpTo"  @blur.native.capture="jumpTo"/>
              <div>页</div>
            </div>
          </b-col>
        </b-row>
      </div>
    </component>
    <b-sidebar
      v-if="!dialogForm"
      :visible="showFormView"
      idebar-class="sidebar-xl"
      bg-variant="white"
      right
      backdrop
      :no-close-on-backdrop="!isViewForm && cancelOnOutside"
      shadow
      :width="formWidth"
      @hidden="hideFormView">
      <div class="ml-2 mt-1 font-medium-2 x-text-bold text-primary"> {{ isViewForm ? '详情' : (isAddForm ? '新增' : '编辑') }} </div>
      <slot name="form-tip" :type="getFormType()" >
        <b-alert
          v-if="options.formTip"
          show
          :variant="options.formTipVariant || 'primary'"
        >
          <div class="alert-body">
            <slot name="form-tip-body" :type="getFormType()" >
              <span class="el-icon-info font-medium-1"></span>
              <span>{{options.formTip}}</span>
            </slot>
          </div>
        </b-alert>
      </slot>
      <slot name="custom-form" :type="getFormType()" :show="showFormView" :row="isAddForm ? null : pageDatas[optRowNo]" :index="optRowNo">
        <x-table-form v-if="showFormView" v-model="formData" class="m-2" ref="tableForm" :labelVertical="labelVertical" :isAdd="isAddForm" :isView="isViewForm"
                      :columns="formColumns" :groupType="formGroupType" :expandAll="collapseExpandAll"
                      @beforeShow="beforeShow">
          <template :slot="`form-${item.prop}`" slot-scope="scope"  v-for="(item) in formColumns">
            <slot v-if="!item.children" :name="`form-${item.prop}`" v-bind="scope"></slot>
            <!--<slot v-else :slot="`form-${item.prop}-${child.prop}`" slot-scope="{data,row}"  v-for="(child,key) in item.children">-->
            <!--<span :key="key">{{ 'form-' + item.prop + '-' + child.prop }}</span>-->
            <!--<slot v-if="!item.children" :name="`form-${item.prop}-${child.prop}`" :data="data" :row="row"></slot>-->
            <!--</slot>-->
          </template>
          <template :slot="`form-${item.propPrefix}-${item.column.prop}`" slot-scope="scope"  v-for="(item) in getChildFormColumns(formColumns)">
            <slot :name="`form-${item.propPrefix}-${item.column.prop}`" v-bind="scope"></slot>
          </template>
        </x-table-form>
      </slot>
      <div v-if="!isViewForm" class="d-flex">
        <b-button
          class="flex-grow-1 ml-2 mr-2 mb-2"
          variant="primary"
          @click="enterSidebarHandle">
          保存
        </b-button>
      </div>
    </b-sidebar>
    <b-modal
      v-if="dialogForm"
      :size="formWidth"
      :visible="showFormView"
      title-class="x-text-bold"
      body-class="x-scrollbar pl-4 pr-4"
      ok-title="保存"
      cancel-title="取消"
      cancel-variant="flat-secondary"
      :title="isViewForm ? '详情' : (isAddForm ? '新增' : '编辑')"
      centered
      :no-close-on-backdrop="cancelOnOutside"
      @hidden="hideFormView"
      @ok="enterDialogHandle">
      <slot name="form-tip" :type="getFormType()" >
        <b-alert
          v-if="options.formTip"
          show
          :variant="options.formTipVariant || 'primary'"
        >
          <div class="alert-body">
            <slot name="form-tip-body" :type="getFormType()" >
              <span class="el-icon-info font-medium-1 mr-50"></span>
              <span>{{options.formTip}}</span>
            </slot>
          </div>
        </b-alert>
      </slot>
      <slot name="custom-form" :type="getFormType()" :row="isAddForm ? null : pageDatas[optRowNo]" :index="optRowNo">
        <x-table-form v-if="showFormView" v-model="formData"  ref="tableForm" :labelVertical="labelVertical" :isAdd="isAddForm" :isView="isViewForm"
                      :columns="formColumns" :groupType="formGroupType" :expandAll="collapseExpandAll"
                      @beforeShow="beforeShow">
          <slot :slot="`form-${item.prop}`" slot-scope="{data,row,type}"  v-for="(item) in formColumns">
            <slot v-if="!item.children" :name="`form-${item.prop}`" :data="data" :row="row" :type="type"></slot>
          </slot>
          <slot :slot="`form-${item.propPrefix}-${item.column.prop}`" slot-scope="{data,row,type}"  v-for="(item) in getChildFormColumns(formColumns)">
            <slot :name="`form-${item.propPrefix}-${item.column.prop}`" :data="data" :row="row" :type="type"></slot>
          </slot>
        </x-table-form>
      </slot>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BTable, BCol, BRow, BPagination, BDropdown, BDropdownItem, BFormInput, BOverlay, BBadge, BButton, BSidebar, BModal,
  BAvatar, BImg, BFormCheckbox, VBTooltip, BAlert, BSpinner,
} from 'bootstrap-vue'
import XIcons from '@core/components/cx/icons/XIcons.vue'
import vSelect from 'vue-select'
import useAppConfig from '@core/app-config/useAppConfig'
import website from '@/config/website'
import XPopconfirm from '@core/components/cx/popconfirm/XPopconfirm.vue'
import { getDictData } from '@/api/system/dict'
import XTableGuide from './XTableGuide.vue'
import XTableActions from './XTableActions.vue'
import XTableForm from './XTableForm.vue'
import XTableCard from './XTableCard.vue'
import { setAddFormData } from './xform'

export default {
  components: {
    BCard,
    XTableGuide,
    XTableActions,
    XTableForm,
    XTableCard,
    BTable,
    BCol,
    BRow,
    BPagination,
    vSelect,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BOverlay,
    BBadge,
    BButton,
    XPopconfirm,
    BSidebar,
    BModal,
    BAvatar,
    BImg,
    BFormCheckbox,
    BAlert,
    BSpinner,
    XIcons,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  filters: {
    getRowSelectVariant: (item, value) => item.selectVariant(value),
  },
  props: {
    /**
     *  表格样式 list card
     */
    tableType: {
      type: String,
      default: 'list',
    },
    /**
     *  自定义form: 使用XTable中的弹窗， form数据托管给框架
     */
    customForm: {
      type: Boolean,
      default: false,
    },
    /**
     *  完全自定义form
     */
    customXForm: {
      type: Boolean,
      default: false,
    },
    /**
     *  新增是否使用自定义form
     */
    customAddForm: {
      type: Boolean,
      default: true,
    },
    /**
     *  无分页
     */
    noPage: {
      type: Boolean,
      default: false,
    },
    /**
     * 分页选项
     */
    pageSizes: {
      type: Array,
      default: () => [],
    },
    /**
     *  引导内容
     */
    content: {
      type: Array,
      default: () => [],
    },
    /**
     *  表格配置
     */
    options: {
      type: Object,
      default: () => {},
    },
    /**
     *  权限列表
     */
    permissions: {
      type: Object,
      default: () => {},
    },
    /**
     *  是否显示加载中
     */
    showLoading: {
      type: Boolean,
      default: true,
    },
    /**
     *  是否显示操作栏
     */
    showActions: {
      type: Boolean,
      default: true,
    },
    /**
     * 是否隐藏搜索栏
     */
    hideSearchBar: {
      type: Boolean,
      default: false,
    },
    /**
     *  是否显示为卡片
     */
    card: {
      type: Boolean,
      default: true,
    },
    /**
     *  标题
     */
    title: {
      type: String,
      default: '',
    },
    /**
     *  斑马纹
     */
    striped: {
      type: Boolean,
      default: false,
    },
    /**
     *  无边框
     */
    borderless: {
      type: Boolean,
      default: false,
    },
    /**
     *  hover效果
     */
    hover: {
      type: Boolean,
      default: true,
    },
    /**
     *  通过将单元格填充减半使表更紧凑。
     */
    small: {
      type: Boolean,
      default: false,
    },
    /**
     *  等比例列。
     */
    fixed: {
      type: Boolean,
      default: false,
    },
    /**
     *  粘性标题的垂直可滚动表
     */
    stickyHeader: {
      type: String,
      default: undefined,
    },
    /**
     *  排序
     */
    sortBy: {
      type: String,
      default: '',
    },
    /**
     *  简化分页。
     */
    simplePagination: {
      type: Boolean,
      default: false,
    },
    /**
     * 表字段的名称，每行包含一个保证的唯一值。 需要tbody转换支持，并且还可以加速表格渲染
     */
    primaryKey: {
      type: String,
      default: 'id',
    },
    /**
     * 列表数据预处理
     */
    dataPreHandle: {
      type: Function,
      default: undefined,
    },
    /**
     *  默认选中行。
     */
    selectedValue: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    currentPage() {
      this.loading = true
      this.$emit('search', { pageIndex: this.currentPage, pageSize: this.perPageSize }, this.searchParams, this.searchDone)
    },
    perPageSize() {
      this.__searchHandle()
    },
  },
  data() {
    return {
      actionOptions: {},
      tableColumns: [],
      formColumns: [],
      // 页数据
      pageDatas: [],
      // 行是否可选
      rowSelectable: false,
      // 行是否单选
      rowSingleSelectable: false,
      // 是否隐藏卡片/列表样式切换按钮
      hideStyleSwitch: false,
      // 是否显示行详情
      // rowDetails: false,
      // 选择的行
      rowSelected: new Map(),
      // 选择的行
      rowModels: {},
      // 是否全选
      rowSelectAll: false,
      // 是否为选择部分行
      // indeterminate: false,
      // 是否使用对话框form
      dialogForm: true,
      // 总数量
      total: 0,
      // 当前页
      currentPage: 1,
      // 每页数量
      perPageSize: 10,
      // 跳转页
      jumpPage: undefined,
      searchParams: {},
      loading: false,
      // 行操作
      actions: [
        { action: this.$x.biz.FormType.VIEW, name: '查看', icon: 'EyeIcon', svg: 'media/icons/duotune/general/gen016.svg', url: undefined },
        { action: this.$x.biz.FormType.EDIT, name: '编辑', icon: 'EditIcon', svg: 'media/icons/duotune/general/gen055.svg' },
        { action: this.$x.biz.FormType.DELETE, name: '删除', icon: 'Trash2Icon', svg: 'media/icons/duotune/abstract/abs012.svg' },
      ],
      // 下拉操作
      dropActions: [],
      // 行操作过滤器：自定义action显示规则
      actionFilter: () => true,
      // 显示form表单
      showFormView: false,
      // 显示删除气泡
      formWidth: undefined,
      // 是否为新增
      isAddForm: false,
      // 表单label是否垂直显示
      labelVertical: true,
      // 是否为查看
      isViewForm: false,
      // 是否为row删除点击
      isRowDelete: false,
      // 表单数据
      formData: undefined,
      // 是否点击空白处关闭
      cancelOnOutside: false,
      // 当前操作行
      optRowNo: -1,
      cardProps: {
        icon: {
          prop: 'icon',
          column: {},
        },
        name: {
          prop: 'name',
          column: {},
        },
      },
      pages: undefined,
      // 表单分组展示：collapse(折叠面板) / step(分布表单)
      formGroupType: 'collapse',
      // 是否展开表单分组（分组使用折叠面板时使用）
      collapseExpandAll: true,
      // 是否为list表格
      isListTable: true,
      // card模式下 每个卡片的宽度
      cardCols: 4,
      // card模式下 每个卡片中列的宽度
      cardColumnCols: 4,
      isDark: {},
      selectedRows: [],
    }
  },
  created() {
    this.isDark = useAppConfig().isDark
    this.isListTable = this.tableType === 'list'
    this.init()
  },
  methods: {
    init() {
      this.getOptions()
      let acolumns = []
      let tcolumns = []
      let fcolumns = []
      this.dialogForm = this.options.formType !== 'sidebar'
      if (this.dialogForm) {
        this.formWidth = this.options.formWidth || 'md'
      } else {
        this.formWidth = this.options.formWidth || '40%'
      }
      let needSearch = true
      this.pages = this.pageSizes
      if (!this.pages || this.pages.length === 0) {
        if (!this.isListTable) {
          if (this.options.cardProps) {
            this.cardProps = { icon: { prop: this.options.cardProps.icon, column: {} }, name: { prop: this.options.cardProps.name, column: {} } }
          }
          this.pages = [{ index: 0, label: '6 条/页', value: 6 }, { index: 1, label: '12 条/页', value: 12 },
            { index: 2, label: '30 条/页', value: 30 }, { index: 3, label: '60 条/页', value: 60 }]
        } else {
          this.pages = [{ index: 0, label: '10 条/页', value: 10 }, { index: 1, label: '20 条/页', value: 20 },
            { index: 2, label: '50 条/页', value: 50 }, { index: 3, label: '100 条/页', value: 100 }]
        }
      }
      // perPageSize 改变触发watch 发送搜索事件
      needSearch = this.perPageSize === this.pages[0].value
      this.perPageSize = this.pages[0].value
      this.rowSelectable = this.options.rowSelectable === true
      if (this.rowSelectable && this.selectedValue && this.selectedValue.length > 0) {
        this.selectedValue.forEach(item => {
          let key = item[this.primaryKey]
          this.rowModels[key] = true
          this.rowSelected.set(key, item)
        })
      }
      this.rowSingleSelectable = this.options.rowSingleSelectable === true
      this.rowDetails = this.options.rowDetails === true
      this.hideStyleSwitch = this.options.hideStyleSwitch === true
      if (this.isListTable && this.rowSelectable) {
        tcolumns.push({
          key: '____check',
          label: '____check',
        })
      }
      this.cancelOnOutside = this.options.cancelOnOutside === false
      if (this.options.actions) {
        this.actions = []
        this.dropActions = []
        if (this.options.actions && this.options.actions.length > website.tableActionFoldCount) {
          this.actions = []
          for (let i = 0; i < this.options.actions.length; i++) {
            if (i < website.tableActionFoldCount - 1) {
              this.actions.push(this.options.actions[i])
            } else {
              this.dropActions.push(this.options.actions[i])
            }
          }
        } else {
          this.actions = this.options.actions
        }
      }
      if (this.options.actionFilter) {
        this.actionFilter = this.options.actionFilter
      }
      this.formGroupType = this.options.formGroupType ? this.options.formGroupType : 'collapse'
      this.collapseExpandAll = this.options.collapseExpandAll !== false
      this.cardCols = this.options.cardCols ? this.options.cardCols : 4
      this.cardColumnCols = this.options.cardColumnCols ? this.options.cardColumnCols : 4
      if (this.options.columns) {
        this.options.columns.forEach(col => {
          let column = this.getGeneralColumn(col)
          this.getTableColumn(col, column)
          this.getActionColumn(col, column)
          this.getFormColumn(col, column)
          this.setChild(col, column)
          if (col.rowShow !== false) {
            // 卡片表格行显示 去除 tiltle
            if (!this.isListTable) {
              if (col.prop === this.cardProps.icon.prop) {
                this.cardProps.icon.column = {
                  key: col.prop,
                  label: col.label,
                  sortable: col.sortable,
                  ...column,
                }
              } else if (col.prop === this.cardProps.name.prop) {
                this.cardProps.name.column = {
                  key: col.prop,
                  label: col.label,
                  sortable: col.sortable,
                  ...column,
                }
              } else {
                tcolumns.push(this.setTableColumn(col, column))
              }
            } else {
              tcolumns.push(this.setTableColumn(col, column))
            }
          }
          acolumns.push(column)
          fcolumns.push(column)
        })
      }
      this.loading = true
      if (this.isListTable && this.options.showActions !== false
        && this.options.actions && this.options.actions.length > 0) {
        tcolumns.push({
          key: '____actions',
          label: '____actions',
          // prop: '____actions',
        })
      }
      this.tableColumns = tcolumns
      this.formColumns = fcolumns
      this.actionOptions.columns = acolumns
      // perPageSize 默认会重置修改 触发search事件
      if (needSearch) {
        this.$emit('search', { pageIndex: this.currentPage, pageSize: this.perPageSize }, this.searchParams, this.searchDone)
      }
    },
    // 获取form类型： 新增、编辑、删除
    getFormType() {
      if (this.isAddForm) {
        return this.$x.biz.FormType.ADD
      }
      return this.isViewForm ? this.$x.biz.FormType.VIEW : this.$x.biz.FormType.EDIT
    },
    setTableColumn(col, column) {
      column.key = col.prop
      column.label = col.label
      column.sortable = col.sortable
      return column
    },
    select() {
    },
    setChild(col, column) {
      if (col.children) {
        column.children = []
        let children = []
        col.children.forEach(child => {
          let childColumn = this.getGeneralColumn(child)
          this.getTableColumn(child, childColumn)
          this.getActionColumn(child, childColumn)
          this.getFormColumn(child, childColumn)
          this.setChild(child, childColumn)
          children.push(childColumn)
        })
        column.children = children
      }
    },
    switchTableType() {
      this.isListTable = !this.isListTable
      this.init()
    },
    searchHandle(searchParams) {
      this.searchParams = searchParams
      this.__searchHandle()
    },
    resetHandle() {
      this.loading = true
      this.searchParams = {}
      this.currentPage = 1
      this.perPageSize = 10
      this.jumpPage = ''
      this.$emit('search', { pageIndex: this.currentPage, pageSize: this.perPageSize }, {}, this.searchDone)
    },
    showAddForm() {
      if (this.customXForm && this.customAddForm) {
        this.$emit('rowAdd', () => this.actionDone('新增成功！'))
        return
      }
      this.optRowNo = this.pageDatas.length
      // 解决XTableForm watch不到的问题
      let tempData = {}
      this.formColumns.forEach(item => setAddFormData(tempData, item, this.collapseExpandAll))
      this.formData = tempData
      this.showFormView = true
      this.isAddForm = true
    },
    enterSidebarHandle() {
      if (this.isViewForm) {
        this.hideFormView()
        return
      }
      if (this.customForm) {
        if (this.isAddForm) {
          this.$emit('rowAdd', null, () => this.actionDone('新增成功！'), this.optRowNo)
        } else {
          this.$emit('rowEdit', null, () => this.actionDone('修改成功！'), this.optRowNo)
        }
        return
      }
      this.$refs.tableForm.validate().then(result => {
        if (result) {
          if (this.isAddForm) {
            this.$emit('rowAdd', this.$refs.tableForm.getFormData(), () => this.actionDone('新增成功！'), this.optRowNo)
          } else {
            this.$emit('rowEdit', this.$refs.tableForm.getFormData(), () => this.actionDone('修改成功！'), this.optRowNo)
          }
        }
      })
    },
    enterDialogHandle(bvModalEvt) {
      if (this.isViewForm) {
        this.hideFormView()
        return
      }
      bvModalEvt.preventDefault()
      if (this.customForm) {
        if (this.isAddForm) {
          this.$emit('rowAdd', null, () => this.actionDone('新增成功！'), this.optRowNo)
        } else {
          this.$emit('rowEdit', null, () => this.actionDone('修改成功！'), this.optRowNo)
        }
        return
      }
      this.$refs.tableForm.validate().then(result => {
        if (result) {
          if (this.isAddForm) {
            this.$emit('rowAdd', this.$refs.tableForm.getFormData(), () => this.actionDone('新增成功！'), this.optRowNo)
          } else {
            this.$emit('rowEdit', this.$refs.tableForm.getFormData(), () => this.actionDone('修改成功！'), this.optRowNo)
          }
        }
      })
    },
    actionDone(content) {
      this.$xtoast.success(content)
      this.hideFormView()
      this.__searchHandle()
    },
    printHandle() {
      this.$emit('print')
    },
    beforeShow(type, data, columns) {
      this.$emit('beforeShow', type, data, columns)
    },
    importHandle() {
      this.$emit('import')
    },
    exportHandle() {
      this.$emit('export')
    },
    __searchHandle() {
      this.loading = true
      this.$emit('search', { pageIndex: this.currentPage, pageSize: this.perPageSize }, this.searchParams || {}, this.searchDone)
    },
    searchDone(data, total) {
      setTimeout(() => {
        this.loading = false
      }, 200)
      this.total = total
      if (!data || data.length === 0) {
        // this.$xtoast.info('无更多数据')
        this.pageDatas = []
      } else {
        this.pageDatas = data
        if (this.dataPreHandle) {
          let temps = this.pageDatas
          this.pageDatas.forEach((item, index) => this.dataPreHandle(item, itemAfter => {
            temps[index] = itemAfter
            if (temps.length === this.pageDatas.length) {
              this.pageDatas = [].concat(temps)
            }
          }))
        }
      }
    },
    getCell(item) {
      return `cell(${item.prop})`
    },
    getHead(item) {
      return `head(${item.prop})`
    },
    getOptions() {
      this.labelVertical = this.options.labelVertical !== false
      this.actionOptions = {
        searchFold: this.options.searchFold !== false,
        labelVertical: this.options.labelVertical,
        // 新增按钮
        addBtn: this.options.addBtn !== false,
        // 新增按钮是否正常大小
        normalAddBtn: this.options.normalAddBtn === true,
        // 导入按钮
        importBtn: this.options.importBtn === true,
        // 导出按钮
        exportBtn: this.options.exportBtn === true,
        // 打印按钮
        printBtn: this.options.printBtn === true,
        // 是否显示重置
        resetBtn: this.options.resetBtn !== false,
      }
    },
    getGeneralColumn(col) {
      return {
        /**
         * 通用属性
         */
        __type: 1, // 1搜索项 2 填充项 3 操作
        label: col.label,
        type: col.type || 'input',
        prop: col.prop,
        tip: col.tip,
        // 是否在搜索条件中显示
        searchShow: col.searchShow | true,
        // 是否在表格中显示
        rowShow: col.rowShow | true,
        // 输入格式化
        format: col.format,
        // 输入掩码
        mask: col.mask,
        // 是否显示label
        labelShow: col.labelShow !== false,
        placeholder: col.placeholder || (((col.type === 'select') ? '请选择' : '请输入') + col.label),
      }
    },
    getActionColumn(col, column) {
      column = Object.assign(column, {
        /**
         * input
         */
        // 输入掩码
        mask: col.mask,
        /**
         *  select
         */
        // 字典数据
        dictData: col.dictData || [],
        // 搜索初始值
        searchValue: col.searchValue,

        // 字典选择的item数据自定义绑定到value中
        // dictValueGetter: this.columns[i].dictValueGetter,
        // 获取字典数据URL
        // dictUrl: this.columns[i].dictUrl,
        // props: {
        //   label: this.columns[i].label,
        //   value: this.columns[i].value,
        // }
      })
      if (col.searchValue) {
        this.searchParams[col.prop] = col.searchValue
      }
      // 字典数据处理----------
      let dictLabel = 'label'
      let dictValue = 'value'
      if (col.props && col.props.label) {
        dictLabel = col.props.label
      }
      if (col.props && col.props.value) {
        dictValue = col.props.value
      }
      switch (col.type) {
        case 'switch': {
          column.onSwitchChange = col.onSwitchChange
          if (!column.onSwitchChange) {
            column.onSwitchChange = () => {}
          }
          break
        }
        case 'radio':
        case 'checkbox':
        case 'select':
        case 'tree': {
          column.props = col.props
          if (!column.props) {
            column.props = {
              label: 'label',
              value: 'value',
            }
          }
          column.props.label = dictLabel
          column.props.value = dictValue
          column.getDictLabel = col.getDictLabel
          column.onDictSelected = col.onDictSelected
          if (!column.getDictLabel) {
            column.getDictLabel = option => {
              if (typeof option === 'object') {
                return option.label + ''
              }
              return option
            }
          }
          if (!column.onDictSelected) {
            column.onDictSelected = () => {}
          }
          break
        }
        default: {
          break
        }
      }
      if (col.dictUrl) {
        getDictData(col.dictUrl).then(resp => {
          const data = resp.data.result
          if (data && data.length > 0) {
            if (col.type === 'tree') {
              column.dictData = data
            } else {
              column.dictData = []
              data.forEach(item => {
                column.dictData.push({
                  row: item,
                  label: item[dictLabel],
                  value: item[dictValue],
                })
              })
            }
          }
        })
      } else if (column.dictData.length > 0) {
        let temp = column.dictData
        column.dictData = []
        temp.forEach(item => {
          column.dictData.push({
            row: item,
            label: item[dictLabel],
            value: item[dictValue],
          })
        })
      }
      return column
    },
    getTableColumn(col, column) {
      column.rowCls = col.rowCls
      column.format = col.format
      column.cardColumnCols = col.cardColumnCols
      column.linkUrl = col.linkUrl
      column.format = col.format
      switch (col.type) {
        case 'radio':
        case 'checkbox':
        case 'select': {
          column.rowSelect = col.rowSelect
          column.rowSelectDot = col.rowSelectDot
          column.selectVariant = col.selectVariant
          if (!column.selectVariant) {
            column.selectVariant = () => (column.rowSelectDot ? 'bg-primary' : 'primary')
          }
          break
        }
        case 'time':
        case 'date':
        case 'datetime':
        case 'datetimerange': {
          column.format = col.format
          break
        }
        // case 'link': {
        //   column.linkUrl = col.linkUrl
        //   break
        // }
        default: {
          column.linkUrl = col.linkUrl
          break
        }
      }
      return column
    },
    getFormColumn(col, column) {
      column.searchShow = col.searchShow !== false
      column.viewShow = col.viewShow !== false
      column.addShow = col.addShow !== false
      column.editShow = col.editShow !== false
      column.addDisable = col.addDisable
      column.editDisable = col.editDisable
      column.pickerOptions = col.pickerOptions
      column.rules = col.rules
      switch (col.type) {
        case 'textarea': {
          column.rows = col.rows || 3
          break
        }
        case 'upload': {
          column.upload = col.upload
          break
        }
        default: {
          column.linkUrl = col.linkUrl
          break
        }
      }
      return column
    },
    getPage(item) {
      this.perPageSize = item.value
      this.__searchHandle()
    },
    jumpTo() {
      let totalPage = this.getTotalPage()
      let jumpPageTemp = this.jumpPage
      if (!this.jumpPage) {
        jumpPageTemp = 1
      } else if (this.jumpPage > totalPage) {
        jumpPageTemp = totalPage
      }
      if (jumpPageTemp !== this.currentPage) {
        this.currentPage = jumpPageTemp
        this.__searchHandle()
      }
    },
    getTotalPage() {
      let pages = parseInt(this.total / this.perPageSize, 10)
      if ((this.total % this.perPageSize) > 0) {
        pages += 1
      }
      return pages
    },
    linkTo(url, item) {
      this.$router.push(url.cxSignMix(item))
    },
    rowDropActionClick(bvEvt) {
      if (this.isRowDelete) {
        bvEvt.preventDefault()
      }
    },
    actionClick(action, data) {
      this.isRowDelete = false
      this.optRowNo = data.index
      if (action.url) {
        this.$router.push(action.url.cxSignMix(data.item))
        return
      }
      if (action.website) {
        window.open(`${action.website.cxSignMix(data.row)}`)
        return
      }
      switch (action.action) {
        case this.$x.biz.FormType.VIEW: {
          if (this.customXForm && action.customXForm !== false) {
            this.$emit('rowView', data.item)
            return
          }
          this.showFormView = true
          this.isAddForm = false
          this.isViewForm = true
          this.formData = this._.cloneDeep(data.item)
          break
        }
        case this.$x.biz.FormType.EDIT: {
          if (this.customXForm && action.customXForm !== false) {
            this.$emit('rowEdit', data.item, () => this.actionDone('编辑成功！'), this.optRowNo)
            return
          }
          this.showFormView = true
          this.isAddForm = false
          this.isViewForm = false
          this.formData = this._.cloneDeep(data.item)
          break
        }
        case 'delete':
          this.isRowDelete = true
          break
        default:
          this.$emit('row' + action.action.charAt(0).toUpperCase() + action.action.slice(1), data.item, () => {
            this.$xtoast.success((action.name ? action.name : '操作') + '成功！')
            this.__searchHandle()
          }, this.optRowNo)
          break
      }
    },
    hideClick() {
      this.isRowDelete = false
    },
    deleteClick(data) {
      this.$emit('rowDelete', data, () => this.deleteDone(), this.optRowNo)
    },
    beforeCardCreate(data) {
      this.$emit('beforeCardCreate', data)
    },
    deleteDone() {
      this.isRowDelete = false
      this.$xtoast.success('删除成功！')
      this.__searchHandle()
    },
    hideFormView() {
      if (!this.customForm && !this.isViewForm && !this.dialogForm && this.$refs.tableForm) {
        this.$refs.tableForm.resetValidator()
      }
      this.showFormView = false
      this.isAddForm = false
      this.isViewForm = false
      this.formData = undefined
      this.$emit('onFormHide')
    },
    onRowSelected(items) {
      console.log(items)
    },
    /**
     * 表头全选
     */
    onAllSelected(state) {
      this.rowSelected.clear()
      this.rowModels = {}
      if (state) {
        this.pageDatas.forEach(row => {
          let key = row[this.primaryKey]
          this.rowModels[key] = true
          this.rowSelected.set(key, row)
        })
      }
      let values = []
      this.rowSelected.forEach(item => {
        values.push(item)
      })
      this.selectedRows = values
      this.$emit('rowSelected', values)
    },
    /**
     * 行选择
     */
    onSingleRowSelected(row) {
      let key = row.item[this.primaryKey]
      if (this.rowSelected.has(key)) {
        this.rowModels[key] = false
        this.rowSelected.delete(key)
      } else {
        if (this.rowSingleSelectable) {
          this.rowModels = {}
          this.rowSelected.clear()
        }
        this.rowModels[key] = true
        this.rowSelected.set(key, row.item)
      }
      this.rowSelectAll = this.rowSelected.size > 0 && this.rowSelected.size === this.pageDatas.length
      let values = []
      this.rowSelected.forEach(item => {
        values.push(item)
      })
      this.selectedRows = values
      this.$emit('rowSelected', values)
    },
    rowSelectedOk() {
      this.$emit('rowSelectedOk', this.selectedRows, () => this.__searchHandle())
    },
    /**
     * 获取表单子columns
     * @param columns
     * @param isIn 是否为内部递归
     * @param parent 递归时父column
     * @returns {Array}
     */
    getChildFormColumns(columns) {
      let childs = []
      columns.forEach(column => {
        if (column.children && column.children.length > 0) {
          column.children.forEach(child => {
            if (!child.children || child.children.length === 0) {
              childs.push({
                propPrefix: column.prop,
                column: child,
              })
            }
            // 暂时不支持第三级递归
            // else {
            //   childs = childs.concat(this.getChildFormColumns(child.children, true, child))
            // }
          })
        }
        // 暂时不支持第三级递归
        // else if (isIn) {
        //   childs.push({
        //     propPrefix: parent.prop,
        //     column,
        //   })
        // }
      })
      return childs
    },
    getRowDictLabel: (col, data) => {
      let value = data[col.prop]
      let valueCol = (col.props && col.props.value) ? col.props.value : 'value'
      if (typeof value === 'object') {
        value = data[col.prop][valueCol]
      }
      // 字典查出值都为字符串
      value += ''
      for (let dict of col.dictData) {
        if ((dict.value + '') === value) {
          return dict
        }
      }
      return {}
    },
  },
}
</script>

<style lang="scss">
  @import "@core/scss/base/bootstrap-extended/_variables.scss";
  .bottomBtn{
    width: 100% !important;
    height: 100%;
    margin: 0px !important;
  }
  .xtable{
    &__nocard{
      box-shadow: none;
      background-color: transparent;
    }
    &__page-focus{
      background-color: $primary;
      .dropdown-item{
        color: #fff !important;
        font-weight: bold !important;
      }
    }
    &__page-to{
      width: 5rem;
    }
    &__link{
      &:hover{
        color: rgba($primary, 0.8) !important;
      }
    }
    &__drop-action{
      width: 3rem;
      margin-top: -1rem;
      margin-bottom: -1rem;
    }
  }

  .dark-layout {
    .btn span {
      color: $white ;
    }
    .xtable{
      &__nocard{
        box-shadow: none;
        background-color: transparent;
      }
      &__link{
        &:hover{
          color: rgba($primary, 0.8) !important;
        }
      }
    }
  }
  .v-select.vs--single .vs__selected {
  }
</style>
